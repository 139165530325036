import { SxProps } from '@mui/material';
import { lightTheme } from '../../theme/index';

const { palette } = lightTheme;

export interface ResponsiveProps {
  xl?: string;
  lg?: string;
  xs?: string;
  ss?: string;
  sm?: string;
  md?: string;
  mm?: string;
  ml?: string;
}

const boxDisplay = (
  display: string,
  justifyContent?: string | ResponsiveProps,
  alignItems?: string | ResponsiveProps
) => ({
  display,
  justifyContent,
  alignItems
});

const textStyle = (
  fontWeight: number,
  fontSize: string | ResponsiveProps,
  lineHeight: string | ResponsiveProps
) => ({
  fontWeight,
  fontSize,
  lineHeight,
  whiteSpace: 'pre-wrap'
});

const styles: Record<string, SxProps> = {
  nextImgMainBox: {
    position: 'relative',
    width: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  },

  infoBoxContainer: {
    ...boxDisplay('flex', 'center', 'center'),
    flexDirection: 'column',
    padding: '0.5rem',
    border: `0.063rem solid ${palette.lightishBluish.main}`,
    borderRadius: '0.5rem',
    height: { xs: '11.25rem', sm: '12.875rem' },
    width: { xs: '8.125rem', sm: '14.125rem' },
    gap: '0.75rem',
    position: 'relative'
  },

  contentBox: {
    ...boxDisplay('flex', 'space-between', 'center'),
    flexDirection: 'column',
    height: '100%',
    paddingY: { sm: '1.75rem', xs: '1.25rem' }
  },

  infoToolTipContainer: {
    position: 'absolute',
    top: '0.5rem',
    left: '0.5rem'
  },

  infoText: {
    ...textStyle(700, '1.15rem', '1.25rem'),
    color: palette.grey[600],
    textAlign: 'center'
  },

  toolTipLabelIcon: {
    '& path': {
      fill: palette.secondary.main
    }
  },

  mainContainer: {
    minHeight: '4.125rem',
    backgroundColor: palette.lightishAqua.main,
    alignItems: 'center',
    paddingInline: '1rem',
    paddingY: '1rem',
    display: 'flex',
    borderRadius: '0.5rem'
  },
  text: {
    color: palette.primary.main
  },
  iconContainer: {
    display: 'flex',
    marginRight: '0.5rem',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.457rem',
    borderRadius: '0.25rem',
    backgroundColor: palette.grey[50]
  },
  icon: {
    color: palette.primary.main
  },
  infoTitleStyles: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  infoTipBase: {
    borderRadius: '0',
    width: '10.25rem',
    padding: '0.5rem'
  },
  loadingBox: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    zIndex: 99,
    transform: 'translate(-50%, -50%)'
  }
};

export default styles;
