// libs
import React from 'react';
import Image from 'next/image';
// utils
import { Box } from '@mui/material';
import { fallbackImageUrl } from '../../utils/Constants/fallbackImageUrl';
import FoxtonsLoader from '../FoxtonsLoader/index';
import styles from './styles';

export interface LibNextImageProps {
  image: {
    src: string;
    alt: string;
  };
  sizes?: string;
  fill?: boolean;
  priority?: boolean;
  loading?: 'lazy' | 'eager';
  absoluteDimensions?: {
    width: number;
    height: number;
  };
  style?: {
    height: string;
    width: string;
    objectFit?: 'contain' | 'cover' | 'none';
    borderRadius?: string;
  };
  unoptimized?: boolean;
  loadingImages?: Array<string>;
  setLoadingImages?: any;
  loadingImageUrl?: string;
  makeContainerFullHeight?: boolean;
}

const getBgStyles = (imgurl: string, height: string) => {
  if (!imgurl || imgurl === '') {
    return {};
  }

  return {
    backgroundImage: `url(${imgurl})`,
    height
  };
};

function LibNextImage({
  image,
  sizes = '100vw',
  fill = false,
  priority = false,
  loading = 'lazy',
  absoluteDimensions = { width: 0, height: 0 },
  style = { width: '100%', height: '100%', objectFit: 'contain' },
  loadingImages = [],
  setLoadingImages,
  unoptimized = true,
  loadingImageUrl,
  makeContainerFullHeight = false
}: LibNextImageProps) {
  return (
    <Box
      sx={{
        ...styles.nextImgMainBox,
        ...{ height: makeContainerFullHeight ? '100%' : 'auto' }
      }}
      style={getBgStyles(loadingImageUrl, style.height)}
    >
      {loadingImages?.includes(image?.src) ? (
        <Box sx={styles.loadingBox}>
          <FoxtonsLoader open hasBackdrop={false} />
        </Box>
      ) : null}
      <Image
        src={
          loadingImages?.includes(image?.src)
            ? fallbackImageUrl
            : image?.src || fallbackImageUrl
        }
        alt={image?.alt || 'image'}
        sizes={sizes}
        fill={fill}
        unoptimized={unoptimized}
        priority={priority}
        loading={loading}
        width={absoluteDimensions?.width}
        height={absoluteDimensions?.height}
        onLoadingComplete={() => setLoadingImages?.(image?.src)}
        style={{
          height: style.height,
          width: style.width,
          objectFit: style.objectFit,
          borderRadius: style.borderRadius
        }}
        onError={({ currentTarget }) => {
          if (currentTarget.src !== fallbackImageUrl) {
            // eslint-disable-next-line no-param-reassign
            currentTarget.onerror = null; // prevents looping
            // eslint-disable-next-line no-param-reassign
            currentTarget.srcset = '';
            // eslint-disable-next-line no-param-reassign
            currentTarget.src = fallbackImageUrl;
          }
        }}
      />
    </Box>
  );
}

export const NextImage = React.memo(LibNextImage);

export default NextImage;
